<template>
  <div class="detail-view">
    <div class="row-view">
      <span class="disabled">单品编号</span>
      <span>{{ resultDetail.auxiliaryId }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">单品名称</span>
      <span>{{ resultDetail.productName }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">下单时间</span>
      <span v-if="resultDetail.createTime">{{
        resultDetail.createTime | date("yyyy-mm-dd hh:MM")
      }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单号</span>
      <span>{{ resultDetail.mainOrderId }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">业务类型</span>
      <span>{{ resultDetail.orderBusinessTypeText }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">子景区</span>
      <span>{{ resultDetail.ownerSubMerchantInfoName }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">商户</span>
      <span>{{ resultDetail.ownerMerchantInfoName }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">产品单价</span>
      <span>{{ resultDetail.totalAmount | money() }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">产品实收</span>
      <span>{{ resultDetail.receivedAmount | money() }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">产品状态</span>
      <span>{{ resultDetail.statusText }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">产品有效期</span>
      <div v-if="resultDetail.beginValidTime">
        {{ resultDetail.beginValidTime || "" | date("yyyy-mm-dd") }} ~
        {{ resultDetail.endValidTime || "" | date("yyyy-mm-dd") }}
      </div>
    </div>
    <div class="row-view">
      <span class="disabled">关联产品编号</span>
      <span>{{ resultDetail.parentAuxiliaryId }}</span>
    </div>
    <div class="row-view mt-1x">
      <span class="disabled">游客类型</span>
      <span>{{ resultDetail.touristType }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">游客优惠</span>
      <span>{{ resultDetail.touristDiscount }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">预约日期</span>
      <span v-if="resultDetail.appointmentDate">{{
        resultDetail.appointmentDate | date("yyyy-mm-dd")
      }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">预约时段</span>
      <div>{{ resultDetail.appointmentTime || "" }}</div>
    </div>
    <div class="row-view">
      <span class="disabled">可核销次数</span>
      <span v-if="resultDetail.canUseNum !== -1">{{
        resultDetail.canUseNum
      }}</span>
      <span v-else> 不限制 </span>
    </div>
    <div class="row-view">
      <span class="disabled">已核销次数</span>
      <span>{{ resultDetail.useNum }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">核销员</span>
      <span>{{ resultDetail.writeOffPersonName }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">核销时间</span>
      <span v-if="resultDetail.writeOffTime">{{
        resultDetail.writeOffTime
      }}</span>
    </div>
    <div
      class="row-view"
      :class="{ 'mt-100rpx': resultDetail.whetherCombineProduct !== 2 }"
    >
      <span class="disabled">核销渠道</span>
      <span>{{ resultDetail.writeOffChannel }}</span>
    </div>
    <div
      class="row-view"
      v-if="resultDetail.whetherCombineProduct === 2"
      :class="{ 'mt-100rpx': resultDetail.whetherCombineProduct === 2 }"
    >
      <span class="align-start disabled">产品</span>
      <div class="text-r">
        <div class="light-blue pv-1x">
          总单号：{{ resultDetail.mainOrderId }}
        </div>
        <div
          v-for="(item, index) in resultDetail.subProducts"
          @click="checkTicketDetail(item.auxiliaryId)"
          :key="index"
          class="light-blue pv-1x"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="row-view mt-4x relative row-view-bottom">
      <!-- 更多操作弹窗 -->
      <div class="more-pop" v-show="isShowMoreOperate" @click="hideMoreOperate">
        <div class="more" @click.stop="ShowMoreOperate">
          <div
            class="more-item"
            v-if="checkAuthorityBtn(resultDetail).includes('补录人脸')"
          >
            <van-uploader :after-read="collectionFace">补录人脸</van-uploader>
          </div>
          <!-- <div class="more-item" @click="changePlayDate">修改有效期</div> -->
          <div
            class="more-item"
            v-if="checkAuthorityBtn(resultDetail).includes('补办')"
            @click="changeReplace"
          >
            补办
          </div>
          <div
            class="more-item"
            @click="isVerificationPop('FORCED_TO_WRITE_OFF')"
          >
            强制核销
          </div>
          <div
            class="more-item"
            v-if="checkAuthorityBtn(resultDetail).includes('激活')"
            @click="changeActivate"
          >
            激活
          </div>
          <div
            class="more-item noborder"
            @click="isVerificationPop('FORCED_TO_REFUND')"
          >
            强制退款
          </div>
          <div class="more-triangle"></div>
        </div>
      </div>
      <div @click="ShowMoreOperate">更多</div>
      <!-- <button
        class="btn bd bd-gray btn-color-gray"
        hover-class="click"
        :disabled="!checkAuthorityBtn(resultDetail).includes('修改预约')"
        @click="changeAppointmentInfo"
      >
        修改预约
      </button> -->
      <button
        class="btn bd bd-primary primary"
        hover-class="click"
        :disabled="
          !checkAuthorityBtn(resultDetail).includes('核销') ||
          (checkAuthorityBtn(resultDetail).includes('核销') &&
            resultDetail.canUseNum !== -1 &&
            resultDetail.canUseNum === resultDetail.useNum)
        "
        @click="verification"
      >
        核销
      </button>
      <!-- <button
        class="btn bd bd-red red"
        hover-class="click"
        :disabled="!checkAuthorityBtn(resultDetail).includes('退款')"
        @click="changeOrderInfo"
      >
        退款
      </button> -->
    </div>
    <!-- 退款弹窗 -->
    <van-overlay
      :show="isShowOrderReturnInfo"
      @click="isShowOrderReturnInfo = false"
    >
      <div class="pop-centent relative" @click.stop>
        <div class="pop-centent-title">退款</div>
        <div class="pop-centent-center">
          <div class="refund-info">
            <div>订单编号：{{ refundProductCheckInfo.orderId }}</div>
            <div>订单状态：{{ refundProductCheckInfo.orderStatusText }}</div>
            <div>本次退款产品：{{ resultDetail.productName }}</div>
            <div>
              本次应退金额：{{
                refundProductCheckInfo.totalLeftAmount | money()
              }}
            </div>
            <div>
              本次退款金额：{{
                refundProductCheckInfo.totalRefundableAmount | money()
              }}
            </div>
          </div>
          <div class="refund-remake">
            <div class="">退款说明</div>
            <van-field
              class="mt-2x refund-textarea"
              v-model="remarks"
              rows="2"
              autosize
              type="textarea"
              placeholder="请输入..."
            />
          </div>
        </div>
        <div class="pop-centent-buttom flex-between">
          <button
            class="pop-centent-btn"
            hover-class="click"
            @click="hideOrderInfo"
          >
            取消
          </button>
          <button
            class="pop-centent-btn confirm"
            hover-class="click"
            :style="{ background: color }"
            @click="refundOrder"
          >
            确认
          </button>
        </div>
      </div>
    </van-overlay>
    <!-- 修改预约弹窗 -->
    <van-overlay
      :show="isShowAppointmentInfo"
      @click="isShowAppointmentInfo = false"
    >
      <div class="pop-centent relative" @click.stop>
        <div class="pop-centent-title">修改预约</div>
        <div class="pop-centent-center">
          <div class="refund-info">
            <div>{{ resultDetail.productName }}</div>
            <div>
              使用有效期：
              {{ resultDetail.beginValidTime || "" | date("yyyy-mm-dd") }} ~
              {{ resultDetail.endValidTime || "" | date("yyyy-mm-dd") }}
            </div>
            <div>
              预约日期：{{ resultDetail.appointmentDate | date("yyyy-mm-dd") }}
            </div>
            <div>预约时段：{{ resultDetail.appointmentTime || "" }}</div>
          </div>
          <div class="flex-between item" @click="showValidDate('endValidTime')">
            <span>延长使用有效期</span>
            <div class="flex-center">
              <span class="light-blue mr-1x">{{ endValidTime }}</span>
              <span class="icon icon-Return- right-ico"></span>
            </div>
          </div>
          <div class="flex-between item" @click="showDatePop">
            <span>改签日期</span>
            <div class="flex-center">
              <span class="light-blue mr-1x">{{ useDate || "请选择" }}</span>
              <span class="icon icon-Return- right-ico"></span>
            </div>
          </div>
          <div class="flex-between item" @click="isShowPicker = true">
            <span>改签时段</span>
            <div class="flex-center">
              <span class="light-blue mr-1x">{{
                appointmentPeriodValue || "请选择"
              }}</span>
              <span class="icon icon-Return- right-ico"></span>
            </div>
          </div>
          <div class="flex-between item">
            <span>改签说明</span>
            <div class="flex-center inp-item">
              <van-field
                class="input"
                v-model="ticketRemarks"
                placeholder="请输入..."
              />
            </div>
          </div>
        </div>
        <div class="pop-centent-buttom flex-between">
          <button
            class="pop-centent-btn"
            hover-class="click"
            @click="changeAppointmentInfo"
          >
            取消
          </button>
          <button
            class="pop-centent-btn confirm"
            hover-class="click"
            :style="{ background: color }"
            @click="confirmAppointmentInfo"
          >
            确认
          </button>
        </div>
      </div>
    </van-overlay>
    <!-- 延长使用有效期弹窗 -->
    <van-popup
      v-model="isShowValid"
      round
      position="bottom"
      :style="{ height: '50vh' }"
    >
      <van-datetime-picker
        type="date"
        v-model="pickerEndValidTime"
        :min-date="minDate"
        @cancel="isShowValid = false"
        @confirm="changeEndValidTime"
      >
      </van-datetime-picker>
    </van-popup>
    <!-- 改签日期弹窗 -->
    <van-popup
      v-model="isShowDatePop"
      round
      position="bottom"
      :style="{ height: '50vh' }"
    >
      <van-datetime-picker
        type="date"
        v-model="usePickerDate"
        :min-date="minDate"
        @cancel="isShowDatePop = false"
        @confirm="changeUseDate"
      >
      </van-datetime-picker>
    </van-popup>
    <!-- 改签时间段 -->
    <van-popup
      v-model="isShowPicker"
      round
      position="bottom"
      :style="{ height: '50vh' }"
    >
      <van-picker
        show-toolbar
        :columns="pickerTimeList"
        @cancel="isShowPicker = false"
        @confirm="changeAppointmentPeriodId"
      >
      </van-picker>
    </van-popup>
    <!-- 补办 -->
    <van-overlay :show="isShowReplace" @click="isShowReplace = false">
      <div class="pop-centent relative" @click.stop>
        <div class="pop-centent-title">补办</div>
        <div class="pop-centent-center">
          <div class="refund-info">
            <div>{{ resultDetail.productName }}</div>
            <div>已办理次数：{{ reissueTimes || 0 }}</div>
          </div>
          <div class="flex-between item">
            <span class="replace-price-title pr-4x">补办费用</span>
            <span class="font-xl" :style="{ color: color }">￥</span>
            <div class="replace-price">
              <van-field
                class="input"
                v-model="replacePrice"
                placeholder="请输入..."
              />
            </div>
          </div>
          <div class="refund-remake">
            <div class="">补办说明</div>
            <van-field
              class="mt-2x refund-textarea"
              v-model="replaceRemarks"
              rows="2"
              autosize
              type="textarea"
              placeholder="请输入..."
            />
          </div>
        </div>
        <div class="pop-centent-buttom flex-between">
          <button
            class="pop-centent-btn"
            hover-class="click"
            @click="changeReplace"
          >
            取消
          </button>
          <button
            class="pop-centent-btn confirm"
            hover-class="click"
            :style="{ background: color }"
            @click="confirmReplace"
          >
            确认
          </button>
        </div>
      </div>
    </van-overlay>
    <!-- 激活 -->
    <active-ticket
      :visible="isShowActivate"
      @close="closeActive"
      @success="loadPage"
      :auxiliary-id="resultDetail.auxiliaryId"
      :field-data="fieldList"
    ></active-ticket>
    <!-- 强制退款/核销 -->
    <message-verify
      :is-show-message-verify="isShowMessageVerify"
      :verify-code-type="verifyCodeType"
      @change-message-verify="changeMessageVerify"
      @success="verifyFinally"
    ></message-verify>
    <!-- 人脸图片 -->
    <van-image-preview
      v-model="showImages"
      :images="[resultDetail.productVoucherInfo]"
    ></van-image-preview>

    <!-- 修改预约弹窗 -->
    <van-overlay :show="isShowPlayDate" @click="isShowPlayDate = false">
      <div class="pop-centent relative" @click.stop>
        <div class="pop-centent-title">修改有效期</div>
        <div class="pop-centent-center">
          <div class="refund-info">
            <div>{{ resultDetail.productName }}</div>
            <div>
              使用有效期：
              {{ resultDetail.beginValidTime || "" | date("yyyy-mm-dd") }} ~
              {{ resultDetail.endValidTime || "" | date("yyyy-mm-dd") }}
            </div>
            <div v-if="resultDetail.afterWriteOffValidTime">
              核销后有效期：
              {{
                resultDetail.afterWriteOffValidTime || "" | date("yyyy-mm-dd")
              }}
            </div>
          </div>
          <div class="flex-between item" @click="showValidDate('endValidTime')">
            <span>延长使用有效期</span>
            <div class="flex-center">
              <span class="light-blue mr-1x">{{ endValidTime }}</span>
              <span class="icon icon-Return- right-ico"></span>
            </div>
          </div>
          <div
            v-if="resultDetail.afterWriteOffValidTime"
            class="flex-between item"
            @click="showValidDate('afterWriteOffValidTime')"
          >
            <span>延长核销后有效期</span>
            <div class="flex-center">
              <span class="light-blue mr-1x">{{ afterWriteOffValidTime }}</span>
              <span class="icon icon-Return- right-ico"></span>
            </div>
          </div>
        </div>
        <div class="pop-centent-buttom flex-between">
          <button
            class="pop-centent-btn"
            hover-class="click"
            @click="changePlayDate"
          >
            取消
          </button>
          <button
            class="pop-centent-btn confirm"
            hover-class="click"
            :style="{ background: color }"
            @click="confirmPlayDate"
          >
            确认
          </button>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  refundProductCheck,
  refundProduct,
  productWriteOff,
  refundProductForce,
  additionalRecordinFace,
  productWriteOffForce,
  ticketChanging,
  ticketValidTime,
  ticketActivite,
  productReissueCheck,
} from "../../api/order";
import { checkAuthorityBtn, dateFormat, fix2Reg } from "../../utils/global";
import { faceAnalyzeUpload, face } from "../../api/iot";
import { getAppointmentTimeRule } from "../../api/product";
import { activateTravellerInfo, commonReissue } from "../../api/cart";
import MessageVerify from "./components/message-verify";
import ActiveTicket from "./components/active-ticket";
import { speak } from "../../utils/hardware";
export default {
  name: "TicketDetail",
  components: { MessageVerify, ActiveTicket },
  props: {
    resultDetail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      checkAuthorityBtn,
      isShowMoreOperate: false, //显示更多弹窗
      isShowOrderReturnInfo: false, //显示退款弹窗
      remarks: "", // 退款备注
      refundProductCheckInfo: [], // 退款信息
      isShowAppointmentInfo: false, // 显示修改预约弹窗
      appointmentList: [], // 分时预约规则
      pickerTimeList: [], // 分时预约时间
      appointmentPeriodId: "", // 分时预约规则id
      appointmentPeriodValue: "", // 分时预约规则值
      ticketRemarks: "", // 改签说明
      endValidTime: dateFormat(this.resultDetail.endValidTime, "yyyy-mm-dd"), // 有效期
      useDate: dateFormat(this.resultDetail.appointmentDate, "yyyy-mm-dd"), // 改签日期
      isShowReplace: false, //是否显示补办弹窗
      replacePrice: "", // 补办费用
      replaceRemarks: "", // 补办说明
      reissueTimes: 0, // 已补办次数
      isShowActivate: false, //是否显示激活弹窗
      isShowMessageVerify: false, // 显示审批发送短信弹窗
      verifyCodeType: "", // 审批发送短信类型 FORCED_TO_REFUND：强制退款 FORCED_TO_WRITE_OFF：强制核销
      fieldList: {}, // 激活字段
      requiredPerson: false, // 是否需要人脸
      personFaceId: "", // 人脸id
      personFaceUrl: "", // 人脸图片Url
      isShowDatePop: false, // 是否显示日期弹窗
      isShowValid: false, // 是否显示延迟日期弹窗
      isShowPicker: false, // 是否显示时间段弹窗
      minDate: new Date(), // 开始日期
      usePickerDate: "", // 弹窗-改签日期
      pickerEndValidTime: "", // 弹窗-有效期
      showImages: false, //点击加载人脸图片
      isShowPlayDate: false, // 是否显示修改有效期弹窗
      dateKey: "", // 延长有效期的key
      afterWriteOffValidTime: dateFormat(
        this.resultDetail.afterWriteOffValidTime,
        "yyyy-mm-dd"
      ), //核销后有效期
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    showAvatar() {
      this.showImages = true;
    },
    //订单详情- 点击票号显示门票详情内容
    checkTicketDetail(item) {
      this.$emit("search-detail", item);
    },
    // 显示日期弹窗
    showDatePop() {
      this.isShowDatePop = true;
    },
    // 选择-改签时段
    changeAppointmentPeriodId(val, key) {
      let tiemItem = this.appointmentList[key];
      this.appointmentPeriodId = tiemItem.id;
      this.appointmentPeriodValue = val;
      this.isShowPicker = false;
    },
    // 选择-改签日期
    changeUseDate() {
      let date = this.usePickerDate;
      let day = this.getDay(date);
      this.useDate = day;
      this.isShowDatePop = false;
    },
    // 将日期选择框选择的值转换
    getDay(date) {
      let year = date.getFullYear();
      function two(val) {
        if (val < 10) val = "0" + val;
        return val;
      }
      let month = two(date.getMonth() + 1);
      let day = two(date.getDate());
      return year + "-" + month + "-" + day;
    },
    // 选择-延长使用有效期
    changeEndValidTime() {
      let date = this.pickerEndValidTime;
      let day = this.getDay(date);
      if (this.dateKey === "endValidTime") {
        this.endValidTime = day;
      } else if (this.dateKey === "afterWriteOffValidTime") {
        this.afterWriteOffValidTime = day;
      }
      this.isShowValid = false;
    },
    // 发送短信验证校验通过回调
    verifyFinally(ret) {
      if (ret) {
        if (this.verifyCodeType === "FORCED_TO_WRITE_OFF") {
          // 强制核销
          this.verificationOff();
        } else if (this.verifyCodeType === "FORCED_TO_REFUND") {
          // 强制退款
          this.forceRefund();
        }
      }
    },
    // 强制退款
    forceRefund() {
      refundProductForce({
        mainOrderId: this.resultDetail.mainOrderId,
        detailId: this.resultDetail.id,
      })
        .then((proRes) => {
          this.$toast(proRes.refundFailMsg);
          this.loadPage(); // 刷新页面
        })
        .catch(() => {});
    },
    // 强制核销
    verificationOff() {
      const that = this;
      productWriteOffForce({
        code: this.resultDetail.auxiliaryId,
      })
        .then(() => {
          that.$toast("核销成功");
          this.addTemporaryNum(); // 计数
          that.loadPage(); // 刷新页面
        })
        .catch(() => {});
    },
    // 临时计数
    addTemporaryNum() {
      let newTemporaryNum = localStorage.newTemporaryNum || 0;
      newTemporaryNum++;
      localStorage.setItem("newTemporaryNum", newTemporaryNum);
    },
    changeMessageVerify(val) {
      this.isShowMessageVerify = val;
    },
    // 显示发送短信验证码弹窗
    isVerificationPop(type) {
      this.verifyCodeType = type;
      this.isShowMessageVerify = true;
    },
    // 补录人脸
    collectionFace(e) {
      const that = this;
      let imageBase64 = e.content;
      // 校验并上传人脸
      faceAnalyzeUpload({ imageBase64: imageBase64 })
        .then((res) => {
          that.createFace(res.src);
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    // 上传人脸
    createFace(imgUrl) {
      let params = {
        imageUrl: imgUrl, // 人脸图片
        type: 1, // 1是会员，0是员工
      };
      face(params)
        .then((res) => {
          additionalRecordinFace({
            faceUrl: imgUrl,
            faceId: res.personId,
            ticketNumber: this.resultDetail.auxiliaryId,
          })
            .then(() => {
              this.$toast("上传成功");
              this.loadPage(); // 刷新页面
            })
            .catch(() => {});
        })
        .catch((err) => {
          throw new Error(err);
        });
    },
    // 关闭激活弹窗
    closeActive() {
      this.isShowActivate = false;
    },
    // 显示/隐藏激活弹窗
    changeActivate() {
      if (!this.isShowActivate) {
        activateTravellerInfo({
          orderDetailId: this.resultDetail.id,
        })
          .then((res) => {
            this.fieldList = res;
            this.isShowActivate = !this.isShowActivate;
          })
          .catch(() => {});
      } else {
        this.isShowActivate = !this.isShowActivate;
      }
    },
    // 确认激活
    confirmActivate() {
      const data = this.$refs.playerForm.validate();
      if (!data) return;
      const { values } = data;
      ticketActivite({
        ticketNumber: this.resultDetail.auxiliaryId,
        userFieldInfoDTOS: values,
      })
        .then(() => {
          this.$toast("激活成功");
          this.loadPage(); // 刷新页面
          this.isShowActivate = false;
        })
        .catch(() => {});
    },
    // 显示/隐藏补办弹窗
    changeReplace() {
      if (!this.isShowReplace) {
        productReissueCheck({
          id: this.resultDetail.id,
        })
          .then((res) => {
            this.replacePrice = res.reissueFee / 100;
            this.reissueTimes = res.reissueTimes;
            this.isShowReplace = !this.isShowReplace;
          })
          .catch(() => {});
      } else {
        this.isShowReplace = !this.isShowReplace;
      }
    },
    // 确认补办
    confirmReplace() {
      if (this.replacePrice && !fix2Reg.test(this.replacePrice)) {
        this.$toast("金额格式错误");
        return;
      }
      commonReissue({
        orderDetailId: this.resultDetail.id,
        remark: this.replaceRemarks,
        changePrice: this.replacePrice * 100, // 补办费用转换为分
      })
        .then((res) => {
          this.$router.push({
            path: "/record/ConfirmOrder",
            query: {
              type: "replace",
              id: res.id,
              totalPrice: this.replacePrice * 100,
              settlementPrice: this.replacePrice * 100,
            },
          });
          this.isShowReplace = false;
        })
        .catch(() => {});
    },
    // 显示修改预约弹窗
    changeAppointmentInfo() {
      if (!this.isShowAppointmentInfo && this.resultDetail.appointmentRuleId) {
        getAppointmentTimeRule({
          id: this.resultDetail.appointmentRuleId,
        }).then((res) => {
          let pickerTimeList = [];
          res.relationList.forEach((item) => {
            let value =
              dateFormat(item.beginTime, "hh:mm") +
              "~" +
              dateFormat(item.endTime, "hh:mm");
            item.value = value;
            pickerTimeList.push(value);
          });
          this.appointmentList = res.relationList;
          this.pickerTimeList = pickerTimeList;
        });
      }
      this.isShowAppointmentInfo = !this.isShowAppointmentInfo;
    },
    // 确认修改预约
    confirmAppointmentInfo() {
      ticketChanging({
        ticketNo: this.resultDetail.auxiliaryId,
        endValidTime: this.endValidTime + " 23:59:59",
        useDate: this.useDate,
        remarks: this.ticketRemarks,
        appointmentPeriodId: this.appointmentPeriodId,
      })
        .then(() => {
          this.$toast("修改成功");
          this.loadPage(); // 刷新页面
          this.isShowAppointmentInfo = false;
        })
        .catch(() => {});
    },
    // 核销
    verification() {
      const that = this;
      this.$dialog
        .confirm({
          message: "是否确认核销？",
          confirmButtonColor: this.color,
        })
        .then(() => {
          productWriteOff({ code: that.resultDetail.auxiliaryId })
            .then((res) => {
              if (res.code !== 500) {
                that.$toast("核销成功");
                that.addTemporaryNum(); // 计数
                that.loadPage(); // 刷新页面
              } else {
                that.$dialog.confirm({
                  message: res.otherMsg,
                  confirmButtonColor: this.color,
                });
                speak(res.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 刷新页面
    loadPage() {
      setTimeout(() => {
        this.$emit("search-detail", this.resultDetail.auxiliaryId);
      }, 900);
    },
    // 显示更多操作
    ShowMoreOperate() {
      this.isShowMoreOperate = true;
    },
    // 隐藏更多操作
    hideMoreOperate() {
      this.isShowMoreOperate = false;
    },
    // 显示退款弹窗
    changeOrderInfo() {
      refundProductCheck({
        mainOrderId: this.resultDetail.mainOrderId,
        detailId: this.resultDetail.id,
      })
        .then((res) => {
          this.refundProductCheckInfo = res;
          this.isShowOrderReturnInfo = true;
        })
        .catch(() => {});
    },
    // 隐藏退款弹窗
    hideOrderInfo() {
      this.isShowOrderReturnInfo = false;
    },
    // 退款
    refundOrder() {
      refundProduct({
        mainOrderId: this.resultDetail.mainOrderId,
        detailId: this.resultDetail.id,
        remarks: this.remarks,
      })
        .then((res) => {
          this.$toast(res.refundFailMsg);
          this.hideOrderInfo();
        })
        .catch(() => {});
    },
    // 修改有效期
    changePlayDate() {
      if (!this.isShowPlayDate && this.resultDetail.appointmentRuleId) {
        getAppointmentTimeRule({
          id: this.resultDetail.appointmentRuleId,
        }).then((res) => {
          let pickerTimeList = [];
          res.relationList.forEach((item) => {
            let value =
              dateFormat(item.beginTime, "hh:mm") +
              "~" +
              dateFormat(item.endTime, "hh:mm");
            item.value = value;
            pickerTimeList.push(value);
          });
          this.appointmentList = res.relationList;
          this.pickerTimeList = pickerTimeList;
        });
      }
      this.isShowPlayDate = !this.isShowPlayDate;
    },
    // 确认修改有效期
    confirmPlayDate() {
      const obj = {
        ticketNo: this.resultDetail.auxiliaryId,
        endValidTime: this.endValidTime + " 23:59:59",
      };
      if (this.afterWriteOffValidTime) {
        obj.afterWriteOffValidTime = this.afterWriteOffValidTime + " 23:59:59";
      }
      ticketValidTime(obj)
        .then(() => {
          this.$toast("修改成功");
          this.loadPage(); // 刷新页面
          this.isShowPlayDate = false;
        })
        .catch(() => {});
    },
    // 延长有效期
    showValidDate(key) {
      this.isShowValid = true;
      this.dateKey = key;
    },
  },
};
</script>

<style scoped lang="less">
@import "./detail";
.more-pop {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1;
  .more {
    position: absolute;
    bottom: 45px;
    left: 17px;
    z-index: 3;
    box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    width: 141px;
    background: #fff;
    .more-item {
      height: 42px;
      line-height: 42px;
      border-bottom: 1px solid #f1f1f1;
      padding: 0px 18px;
      color: #272727;
    }
    .noborder {
      border-bottom: none;
    }
    .more-triangle {
      width: 0;
      height: 0;
      border-top: 10px solid #fff;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      position: absolute;
      bottom: -9px;
      left: 18px;
    }
  }
}
.item {
  height: 46px;
  border-bottom: 1px solid #f1f1f1;
  padding: 0px 16px;
  font-size: 12px;
  .inp-item {
    width: 77%;
  }
  .replace-price-title {
    border-right: 1px solid #f1f1f1;
  }
  .replace-price {
    width: 50%;
    padding-left: 9px;
  }
  .price-symbol {
    margin-left: 15px;
  }
}
.no-border {
  border: none;
}
.input {
  height: 45px;
}
.avatar {
  width: 45px;
  height: 45px;
  border-radius: 4px;
}
.row-view-bottom {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 1;
  padding-bottom: 10px;
}
.mt-100rpx {
  margin-bottom: 60px;
}
</style>
