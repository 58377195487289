<template>
  <!-- 强制退款/核销发送验证码 -->
  <div>
    <van-overlay :show="isShowMessageVerify" @click="showCoerceRefund">
      <div class="pop-centent relative" @click.stop>
        <div class="pop-centent-title">
          强制{{
            verifyCodeType === "FORCED_TO_REFUND" ? "退款" : "核销"
          }}审批验证码
        </div>
        <div class="pop-centent-center">
          <van-form
            :model="refundForm"
            ref="refundForm"
            @submit="clickVerifyMessage"
          >
            <van-field
              v-model="refundForm.validateCode"
              center
              clearable
              required
              name="validateCode"
              label="短信验证码"
              placeholder="请输入短信验证码"
              :rules="[
                { required: true, message: '请输入短信验证码' },
                { validator, message: '验证码为6位数字' },
              ]"
            >
              <template #button>
                <van-button size="small" @click="getCode" type="primary">
                  {{ tips }}
                </van-button>
              </template>
            </van-field>
            <div class="pop-centent-buttom flex-between">
              <van-button
                type="info"
                native-type="button"
                class="pop-centent-btn"
                hover-class="click"
                @click="showCoerceRefund"
              >
                取消
              </van-button>
              <van-button
                type="info"
                native-type="submit"
                class="pop-centent-btn confirm"
                hover-class="click"
                :style="{ background: color }"
              >
                确认
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { sendMessageVerify, verifyMessageVerify } from "../../../api/message";
export default {
  name: "message-verify",
  props: {
    // 展示弹框
    isShowMessageVerify: {
      type: Boolean,
      default: false,
    },
    // 验证码类型
    verifyCodeType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      // 退款验证码
      refundForm: {
        validateCode: "",
      },
      tips: "获取验证码",
      canGetCode: true, // 是否可以再次发送
      timer: null, // 定时器
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  methods: {
    validator(val) {
      return /^[0-9]{6}$/.test(val);
    },
    // 校验强制退款验证码校验
    clickVerifyMessage() {
      this.$refs.refundForm
        .validate()
        .then(() => {
          verifyMessageVerify({
            verifyCodeType: this.verifyCodeType,
            verifyCode: this.refundForm.validateCode,
          })
            .then((res) => {
              if (res) {
                this.$emit("success", true);
                this.showCoerceRefund();
              } else {
                this.$toast("验证码错误");
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 显示/隐藏弹窗
    showCoerceRefund() {
      this.$emit("change-message-verify", !this.isShowMessageVerify);
      this.refundForm.validateCode = "";
    },
    codeChange(text) {
      this.tips = text;
    },
    getCode() {
      if (this.canGetCode) {
        sendMessageVerify({
          verifyCodeType: this.verifyCodeType,
        })
          .then(() => {
            this.canGetCode = false;
            // 模拟向后端请求验证码
            this.$toast.loading({
              message: "正在获取验证码",
              forbidClick: true,
            });
            let times = 120;
            clearTimeout(this.timer);
            this.timer = setInterval(() => {
              times -= 1;
              this.tips = times + "秒重发";
              if (times === 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.tips = "重新获取";
                this.canGetCode = true;
              }
            }, 1000);
          })
          .catch(() => {});
      } else {
        this.$toast("倒计时结束后再发送");
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "../detail";
</style>
