<template>
  <div class="detail-view">
    <div class="row-view">
      <span class="disabled">总单号</span>
      <span>{{ detailData.id }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单业态集</span>
      <span>{{ detailData.orderFormatTypeText }}</span>
    </div>
    <div
      class="row-view"
      v-for="(item, index) in detailData.ownerMerchants"
      :key="index"
    >
      <span class="disabled">总单商户集</span>
      <span>{{ item.merchantName }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">销售员</span>
      <span>{{ detailData.salespersonName }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">业务类型</span>
      <span>{{ detailData.orderBusinessTypeText }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">下单时间</span>
      <span>{{ detailData.createTime | date("yyyy-mm-dd hh:MM:ss") }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单实收</span>
      <span>{{ detailData.settlementPrice | money() }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单退款金额</span>
      <span>{{ detailData.totalRefundAmount | money() }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单实收金额</span>
      <span>{{ detailData.totalLeftAmount | money() }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单优惠活动</span>
      <span>{{ detailData.merchantDiscountAmount }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">优惠金额</span>
      <span>{{ detailData.merchantDiscountAmount }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">积分抵扣</span>
      <span>{{ detailData.integralDeductionAmount }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">支付方式</span>
      <span>{{ detailData.payWayName || "" }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">支付通道</span>
      <span>{{ detailData.payChannels || "" }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">下单渠道</span>
      <span>{{ detailData.buyChannelsText || "" }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">团体名称</span>
      <span>{{ detailData.distributorGroupName || "" }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">团体类型</span>
      <span>{{ detailData.groupTypeName || "" }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单状态</span>
      <span>{{ detailData.orderStatusText }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">外部单号</span>
      <span>{{ detailData.outsideCode || "" }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单开票状态</span>
      <span>{{ detailData.billingStatusText || "" }}</span>
    </div>
    <div class="row-view">
      <span class="disabled">总单开票时间</span>
      <span>{{ detailData.invoiceTime | date("yyyy-mm-dd hh:MM:ss") }}</span>
    </div>
    <div class="row-view mt-100rpx">
      <span class="align-start disabled">产品</span>
      <div>
        <div
          v-for="(item, index) in detailData.ticketInfoList"
          @click="checkTicketDetail(item.auxiliaryId)"
          :key="index"
          class="light-blue pv-1x"
        >
          {{ item.productName }}
        </div>
      </div>
    </div>
    <!-- 底部操作按钮 -->
    <div class="row-view row-view-bottom">
      <button
        :disabled="checkIsReturn"
        :class="{ 'disabled-btn': checkIsReturn }"
        class="btn bd bd-gray btn-color-gray"
        hover-class="click"
        @click="chargeback"
      >
        退单
      </button>
      <button
        v-if="detailData.payWayCode === 'OnCredit'"
        class="btn bd bd-primary primary ml-2x"
        hover-class="click"
        @click="onCredit"
      >
        清账
      </button>
      <button
        v-if="detailData.ticketInfoList"
        class="btn bd bd-primary primary ml-2x"
        hover-class="click"
        @click="loadTicket"
      >
        {{ detailData.ticketInfoList.length > 1 ? "批量" : "" }}核销
      </button>
      <button
        class="btn bd bd-red red ml-2x"
        :class="{ 'disabled-btn': checkIsForceReturn }"
        hover-class="click"
        @click="showCoerceRefund"
      >
        强制退单
      </button>
    </div>
    <!-- 批量核销弹窗 -->
    <van-overlay
      :show="showVerificationPop"
      @click="showVerificationPop = false"
    >
      <div class="pop-centent relative" @click.stop>
        <div class="pop-centent-title">选择核销产品</div>
        <div class="pop-centent-center">
          <div class="">
            <van-checkbox-group v-model="checkboxValue">
              <van-checkbox
                v-for="(item, index) in checkboxList"
                :key="index"
                shape="square"
                :name="item.auxiliaryId"
                :checked-color="color"
                class="checkbox"
              >
                {{ item.productName }}
                <p class="ticket-no">票号：{{ item.auxiliaryId }}</p>
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
        <div class="pop-centent-buttom flex-between">
          <button
            class="pop-centent-btn click"
            @click="showVerificationPop = false"
          >
            取消
          </button>
          <button
            class="pop-centent-btn click confirm"
            :style="{ background: color }"
            @click="verificationConfirm"
          >
            确认核销
          </button>
        </div>
      </div>
    </van-overlay>
    <!-- 强制退单 -->
    <van-overlay :show="isShowCoerceRefund" @click="isShowCoerceRefund = false">
      <div class="pop-centent relative" @click.stop>
        <div class="pop-centent-title">强制退款审批验证码</div>
        <div class="pop-centent-center">
          <van-form
            :model="refundForm"
            ref="refundForm"
            @submit="clickVerifyMessage"
          >
            <van-field
              v-model="refundForm.validateCode"
              center
              clearable
              required
              name="validateCode"
              label="短信验证码"
              placeholder="请输入短信验证码"
              :rules="[
                { required: true, message: '请输入短信验证码' },
                { validator, message: '验证码为6位数字' },
              ]"
            >
              <template #button>
                <van-button size="small" @click="getCode" type="primary">
                  {{ tips }}
                </van-button>
              </template>
            </van-field>
            <div class="pop-centent-buttom flex-between">
              <button
                class="pop-centent-btn"
                hover-class="click"
                @click="showCoerceRefund"
              >
                取消
              </button>
              <van-button
                type="info"
                native-type="submit"
                class="pop-centent-btn confirm"
                hover-class="click"
                :style="{ background: color }"
              >
                确认
              </van-button>
            </div>
          </van-form>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import {
  refundOrderCheck,
  refundOrder,
  refundOrderForce,
  apiOrderTicketCheckArray,
  productWriteOff,
} from "../../api/order";
import { orderTicketverify } from "../../api/search";
import { sendMessageVerify, verifyMessageVerify } from "../../api/message";
import { speak } from "../../utils/hardware";
export default {
  name: "OrderDetail",
  props: {
    detailData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showVerificationPop: false, // 是否显示批量核销弹窗
      checkboxList: [],
      checkboxValue: [],
      isShowCoerceRefund: false, // 是否显示强制退单弹窗
      // 退款验证码
      refundForm: {
        validateCode: "",
      },
      tips: "获取验证码",
      canGetCode: true, // 是否可以再次发送
      timer: null, // 定时器
    };
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
    // 是否可以退单
    checkIsReturn() {
      // OrderStatus 1未支付 2支付中 3已支付 4待发货 5待收货 6已完成 7已取消 8已退款 9部分退款 10退款中 11退款审核中 12退款失败 13挂账 14 清账 15支付失败 16未使用 17已使用 18部分使用 19强制退款 10部分强制退款
      if (
        this.detailData.orderStatus === 3 ||
        this.detailData.orderStatus === 16 ||
        this.detailData.orderStatus === 9 ||
        this.detailData.orderStatus === 18
      ) {
        return false;
      } else {
        return true;
      }
    },
    // 是否可以强制退单
    checkIsForceReturn() {
      // OrderStatus 1未支付 2支付中 3已支付 4待发货 5待收货 6已完成 7已取消 8已退款 9部分退款 10退款中 11退款审核中 12退款失败 13挂账 14 清账 15支付失败 16未使用 17已使用 18部分使用 19强制退款 10部分强制退款
      if (
        this.detailData.orderStatus === 17 ||
        this.detailData.orderStatus === 18
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    validator(val) {
      return /^[0-9]{6}$/.test(val);
    },
    //订单详情- 点击票号显示门票详情内容
    checkTicketDetail(item) {
      this.$emit("search-detail", item);
    },
    // 显示/隐藏强制退单
    showCoerceRefund() {
      this.isShowCoerceRefund = !this.isShowCoerceRefund;
    },
    // 校验强制退款验证码校验
    clickVerifyMessage() {
      this.$refs.refundForm
        .validate()
        .then(() => {
          verifyMessageVerify({
            verifyCodeType: "FORCED_TO_REFUND",
            verifyCode: this.refundForm.validateCode,
          })
            .then((res) => {
              if (res) {
                // 强制退款
                refundOrderForce({
                  mainOrderId: this.detailData.id,
                })
                  .then(() => {
                    this.showCoerceRefund();
                  })
                  .catch(() => {});
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    getCode() {
      if (this.canGetCode) {
        sendMessageVerify({
          verifyCodeType: "FORCED_TO_REFUND",
        })
          .then(() => {
            this.canGetCode = false;
            // 模拟向后端请求验证码
            this.$toast.loading({
              message: "正在获取验证码",
              forbidClick: true,
            });
            let times = 120;
            clearTimeout(this.timer);
            this.timer = setInterval(() => {
              times -= 1;
              this.tips = times + "秒重发";
              if (times === 0) {
                clearInterval(this.timer);
                this.timer = null;
                this.tips = "重新获取";
                this.canGetCode = true;
              }
            }, 1000);
          })
          .catch(() => {});
      } else {
        this.$toast("倒计时结束后再发送");
      }
    },
    // 退单
    chargeback() {
      const detailData = this.detailData;
      this.$dialog
        .confirm({
          message: "是否确认退款？",
          confirmButtonColor: this.color,
        })
        .then(() => {
          // 整单退款校验查询
          refundOrderCheck({ mainOrderId: detailData.id })
            .then(() => {
              // 整单退款
              refundOrder({ mainOrderId: detailData.id })
                .then((reData) => {
                  this.$toast(reData.refundStatusText);
                  this.loadPage();
                })
                .catch(() => {});
            })
            .catch(() => {
              this.loadPage();
            });
        })
        .catch(() => {});
    },
    // 刷新页面
    loadPage() {
      setTimeout(() => {
        this.$emit("search-detail", this.detailData.id);
      }, 900);
    },
    // 需要核销的门票列表
    loadTicket() {
      this.checkboxValue = []; // 初始化
      orderTicketverify({
        keyword: this.detailData.id,
      }).then((res) => {
        const arr = res.filter((item) => {
          if (item.canUseNum !== "-1" && item.canUseNum !== item.useNum) {
            return true;
          } else {
            return false;
          }
        });
        if (arr && arr.length) {
          if (arr.length === 1) {
            this.verification(arr[0].auxiliaryId);
          } else {
            this.showVerificationPop = true;
            this.checkboxList = arr;
          }
        } else {
          this.$toast("暂无可核销产品!");
        }
      });
    },
    // 核销
    verification(code) {
      const that = this;
      this.$dialog
        .confirm({
          message: "是否确认核销？",
          confirmButtonColor: this.color,
        })
        .then(() => {
          productWriteOff({ code })
            .then((res) => {
              if (res.code !== 500) {
                that.$toast("核销成功");
                this.addTemporaryNum(); // 计数
                that.loadPage(); // 刷新页面
                speak("核销成功");
              } else {
                that.$dialog.confirm({
                  message: res.otherMsg,
                  confirmButtonColor: this.color,
                });
                speak(res.msg);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    // 核销确认
    verificationConfirm() {
      if (this.checkboxValue.length !== 0) {
        apiOrderTicketCheckArray(
          {
            codeList: this.checkboxValue,
          },
          { catch: false }
        )
          .then(() => {
            speak("核销成功");
            this.addTemporaryNum(); // 计数
            this.$toast.success("核销成功!");
          })
          .catch((res) => {
            this.$toast.fail(res.msg ? res.msg : "核销失败");
            speak(res.msg ? res.msg : "核销失败");
          });
      }
    },
    // 临时计数
    addTemporaryNum() {
      let newTemporaryNum = localStorage.newTemporaryNum || 0;
      newTemporaryNum++;
      localStorage.setItem("newTemporaryNum", newTemporaryNum);
    },
    // 清账
    onCredit() {
      this.$router.push({
        path:
          "/record/CloseoutAccount?showSearch=false&orderId=" +
          this.detailData.id,
      });
    },
  },
};
</script>

<style scoped lang="less">
@import "./detail";
.row-view-bottom {
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 1;
  background: #fff;
}
.mt-100rpx {
  margin-bottom: 50px;
}
.ticket-no {
  font-size: 12px;
}
.disabled-btn {
  color: #c0c4cc;
  border-color: #c0c4cc !important;
}
</style>
