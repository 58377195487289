<template>
  <!-- 
    激活组件 
    props: {
      // 展示
      visible: {
        type: Boolean,
        default: false,
      },
      // 动态表单需要字段
      fieldData: {
        type: Object,
        default() {
          return {};
        },
      },
      // 票号
      auxiliaryId: {
        type: [String, Number],
        default: "",
      },
    },
    事件：close 关闭 success 成功
  -->
  <div>
    <van-overlay :show="visible" @click="close">
      <div class="pop-centent relative" @click.stop>
        <van-form @submit="confirmActivate" ref="formData">
          <div class="pop-centent-title">激活</div>
          <div class="pop-centent-center">
            <!--非证件类型字段-->
            <!-- 1文本,2日期,3下拉选择,4姓名,5手机,6身份证,7生日,8性别,9证件照,10司机姓名, 11司机车牌号,12地址,13邮箱,14微信openId,15证件类型,16国家,17台胞证,18港澳通行证 19护照 -->
            <div v-for="(temp, idx) in formData.visitorFieldList" :key="idx">
              <!-- 1文本 -->
              <van-field
                v-if="temp.fieldType === 1"
                v-model="temp.fieldValue"
                :name="temp.fieldName"
                :placeholder="temp.fieldName"
                :label="temp.fieldName"
                :prop="`visitorFieldList.${idx}.fieldValue`"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: temp.required && checkText,
                    message: `请检查${temp.fieldName}`,
                    trigger: 'blur',
                  },
                ]"
              />
              <!-- 4, 姓名 10司机姓名 11司机车牌号 12地址 14微信号 -->
              <van-field
                v-if="[4, 10, 11, 12, 14].includes(temp.fieldType)"
                v-model="temp.fieldValue"
                :name="temp.fieldName"
                :label="temp.fieldName"
                :placeholder="temp.fieldName"
                :required="temp.required"
                :disabled="temp.memberField ? true : false"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: temp.required,
                    message: `请填写${temp.fieldName}`,
                  },
                ]"
              />
              <!-- 5手机 -->
              <van-field
                v-if="temp.fieldType === 5"
                v-model="temp.fieldValue"
                :name="temp.fieldName"
                :label="temp.fieldName"
                :placeholder="temp.fieldName"
                :required="temp.required"
                :disabled="temp.memberField ? true : false"
                input-align="right"
                error-message-align="right"
                @blur="blurChange(temp)"
                :rules="[
                  {
                    validator: temp.required && checkPhone,
                    required: temp.required,
                    message: `请检查${temp.fieldName}`,
                    trigger: 'blur',
                  },
                ]"
              />
              <!-- 身份证 -->
              <van-field
                v-else-if="temp.fieldType === 6"
                v-model="temp.fieldValue"
                :name="temp.fieldName"
                :label="temp.fieldName"
                :placeholder="temp.fieldName"
                :required="temp.required"
                :disabled="temp.memberField ? true : false"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    validator: temp.required && checkIdCard,
                    required: temp.required,
                    message: `请检查${temp.fieldName}`,
                  },
                ]"
                @focus="getIdCard(temp)"
                @blur="blurChange(temp)"
              />
              <!-- 生日 -->
              <div v-else-if="temp.fieldType === 7">
                <van-field
                  readonly
                  clickable
                  is-link
                  name="picker"
                  :value="temp.fieldValue"
                  :label="temp.fieldName"
                  :required="temp.required"
                  :disabled="temp.memberField ? true : false"
                  input-align="right"
                  placeholder="请选择"
                  @click="openDate(temp)"
                />
              </div>
              <!-- 性别 -->
              <van-field
                v-else-if="temp.fieldType === 8"
                readonly
                clickable
                is-link
                name="picker"
                :value="temp.fieldValue"
                :label="temp.fieldName"
                :required="temp.required"
                :disabled="temp.memberField ? true : false"
                input-align="right"
                error-message-align="right"
                placeholder="请选择"
                @click="openPopup(temp)"
              />
              <!-- 证件照 -->
              <van-cell v-else-if="temp.fieldType === 9">
                <template #title>
                  <span class="custom-title">{{ temp.fieldName }}</span>
                </template>
                <template #extra>
                  <picture-input
                    class="m-x"
                    v-model="temp.fieldValue"
                  ></picture-input>
                </template>
              </van-cell>
              <!-- 证件类型 -->
              <div class="flex-start" v-else-if="temp.fieldType === 15">
                <van-field
                  readonly
                  clickable
                  class="prefix-picker"
                  name="picker"
                  :value="temp.fieldName"
                  placeholder="证件类型"
                  @click="toShowPicker(temp)"
                />
                <van-field
                  class="prefix-content"
                  v-model="temp.fieldValue"
                  :required="temp.required"
                  :name="item.voucherName"
                  placeholder="证件信息"
                  input-align="right"
                  :rules="[
                    {
                      required: temp.required,
                      message: '请填写证件信息',
                    },
                  ]"
                  @blur="changeVoucher(item, temp)"
                />
              </div>
              <!-- 国家 -->
              <van-field
                v-else-if="temp.fieldType === 16"
                readonly
                clickable
                is-link
                name="picker"
                :value="temp.fieldValue"
                :label="temp.fieldName"
                :required="temp.required"
                input-align="right"
                placeholder="请选择"
                @click="openPopup(temp)"
              />
              <!-- 台胞证 -->
              <van-field
                v-else-if="temp.fieldType === 17"
                v-model="temp.fieldValue"
                :name="temp.fieldName"
                :label="temp.fieldName"
                :placeholder="temp.fieldName"
                :required="temp.required"
                :disabled="temp.memberField ? true : false"
                input-align="right"
                :rules="[
                  {
                    required: temp.required,
                    message: `请检查${temp.fieldName}`,
                  },
                ]"
              />
              <!-- 港澳通行证 -->
              <van-field
                v-else-if="temp.fieldType === 18"
                v-model="temp.fieldValue"
                :name="temp.fieldName"
                :label="temp.fieldName"
                :placeholder="temp.fieldName"
                :required="temp.required"
                :disabled="temp.memberField ? true : false"
                input-align="right"
                :rules="[
                  {
                    required: temp.required,
                    message: `请检查${temp.fieldName}`,
                  },
                ]"
              />
              <!-- 护照 -->
              <van-field
                v-else-if="temp.fieldType === 19"
                v-model="temp.fieldValue"
                input-align="right"
                error-message-align="right"
                :name="temp.fieldName"
                :label="temp.fieldName"
                :placeholder="temp.fieldName"
                :required="temp.required"
                :disabled="temp.memberField ? true : false"
                :rules="[
                  {
                    required: temp.required,
                    message: `请检查${temp.fieldName}`,
                  },
                ]"
              />
              <!-- 社保卡 -->
              <van-field
                v-else-if="temp.fieldType === 21"
                v-model="temp.fieldValue"
                :name="temp.fieldName"
                :label="temp.fieldName"
                :placeholder="temp.fieldName"
                :required="temp.required"
                :disabled="temp.memberField ? true : false"
                input-align="right"
                error-message-align="right"
                :rules="[
                  {
                    required: temp.required,
                    message: `请检查${temp.fieldName}`,
                  },
                ]"
              />
              <!-- 卡腕带 -->
              <van-field
                v-if="formData.requiredVoucherNo"
                v-model="temp.fieldValue"
                :name="temp.fieldName"
                :label="temp.fieldName"
                :placeholder="temp.fieldName"
                :required="temp.required"
                input-align="right"
                error-message-align="right"
                :disabled="temp.memberField ? true : false"
                :rules="[
                  {
                    required: temp.required,
                    message: `请检查${temp.fieldName}`,
                  },
                ]"
              />
            </div>
            <div v-if="formData.requiredPersonId" class="person-face-url">
              <div>
                <span class="red" v-show="formData.requiredPersonTrue">*</span>
                人脸
              </div>
              <FaceInput
                v-model="formData.personId"
                :echoimgurl="formData.personFaceUrl"
                @imgUrlChange="(res) => faceChange(formData, res)"
              />
            </div>
          </div>
          <div class="pop-centent-buttom flex-between">
            <van-button
              class="pop-centent-btn"
              hover-class="click"
              native-type="button"
              @click="close"
            >
              取消
            </van-button>
            <van-button
              class="pop-centent-btn confirm"
              hover-class="click"
              native-type="submit"
              :style="{ background: color }"
            >
              确认
            </van-button>
          </div>
        </van-form>
      </div>
    </van-overlay>
    <!-- value弹窗 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="fieldOptions"
        @confirm="(val) => checkValueField(formData.visitorFieldList, val)"
        @cancel="showPicker = false"
      />
    </van-popup>
    <!-- label弹窗 -->
    <van-popup v-model="showLabelPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="fieldOptions"
        @confirm="(val) => checkField(formData.visitorFieldList, val)"
        @cancel="showLabelPicker = false"
      />
    </van-popup>
    <van-calendar
      v-model="showDate"
      @confirm="(val) => dateValueChange(catchItem, val)"
      :min-date="new Date('1930')"
      :max-date="new Date(defaultData)"
    />
  </div>
</template>

<script>
import {
  phoneReg,
  isIdcard,
  checkEmail,
  nameReg,
  textReg,
} from "../../../utils/global";
import { ticketActivite } from "../../../api/order";
import { evidenceOneMemberVisitor } from "../../../api/member";
import FaceInput from "../../home-new/components/FaceInput.vue";
import PictureInput from "../../home-new/components/PictureInput.vue";
import moment from "moment";
export default {
  name: "active-ticket",
  components: { FaceInput, PictureInput },
  props: {
    // 展示
    visible: {
      type: Boolean,
      default: false,
    },
    // 动态表单需要字段
    fieldData: {
      type: Object,
      default() {
        return {};
      },
    },
    // 票号
    auxiliaryId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      moment,
      phoneReg,
      isIdcard,
      nameReg,
      textReg,
      checkEmail,
      formData: {
        visitorFieldList: [], // 游客信息字段
        IdTypeList: [], // 出游人证件类型归类
        IdType: "", // 证件类型
        personId: "", // 人脸id
        personFaceUrl: "", // 人脸url
      },
      showDate: false,
      fieldType: "", //当前选择类型
      fieldFisible: false,
      openPopupIndex: 0, //当前点击游客信息
      catchItem: [],
      fieldOptions: [], //点击游客信息选项
      showPicker: false,
      showVoucher: false, //产品凭证
      showLabelPicker: false,
      defaultData: "", // 默认日期
    };
  },
  watch: {
    fieldData(newVal) {
      this.initialized(newVal);
    },
  },
  computed: {
    // 主题配色变量
    color() {
      return this.$store.getters["common/color"].primary;
    },
  },
  created() {
    this.defaultData = moment().format("YYYY-MM-DD");
  },
  methods: {
    // 初始数据
    initialized(val) {
      // 如果无需填写任何数据，则直接激活
      if (
        !val.visitorFieldList.length &&
        !val.requiredPersonId &&
        !val.requiredVoucherNo
      ) {
        this.close();
        ticketActivite({
          ticketNumber: this.auxiliaryId, // 票号
        }).then(() => {
          this.$toast("激活成功");
          this.$emit("success");
        });
        return;
      }
      // 处理出游人信息字段，把身份证、护照、港澳通行证、台胞证统一合并为证件类型，只能填写一个
      const visitorFieldList1 = [];
      const visitorFieldList2 = [];
      let IdType = "";
      let formData = this.formData;
      val.visitorFieldList.forEach((temp) => {
        if (
          temp.fieldType === 6 ||
          temp.fieldType === 17 ||
          temp.fieldType === 18 ||
          temp.fieldType === 19 ||
          temp.fieldType === 21
        ) {
          visitorFieldList1.push(temp);
        } else {
          visitorFieldList2.push(temp);
        }
      });
      formData.IdType =
        IdType || (visitorFieldList1[0] ? visitorFieldList1[0].fieldType : ""); // 证件类型
      formData.IdTypeList = visitorFieldList1; // 证件类型列表
      let arr1 = [];
      let arr2 = [];
      // 排序，证件照放置最后
      visitorFieldList2.forEach((item) => {
        if (item.fieldType === 9) {
          arr2.push(item);
        } else arr1.push(item);
      });
      formData.visitorFieldList = arr1.concat(arr2); // 游客信息
      formData.requiredPersonId = val.requiredPersonId;
      formData.requiredPersonTrue = val.requiredPersonTrue;
      formData.requiredVoucherNo = val.requiredVoucherNo;
    },
    // 打开label弹窗
    toShowPicker(item) {
      this.fieldType = item.fieldType;
      this.fieldOptions = item.fieldOptions;
      this.showLabelPicker = true;
    },
    //label选择选项
    checkField(visitorFieldList, val) {
      visitorFieldList.forEach((element) => {
        if (element.fieldType == this.fieldType) {
          element.fieldName = val;
        }
      });
      this.showLabelPicker = false;
    },
    // 打开value弹窗
    openPopup(item) {
      if (item.memberField) {
        return;
      }
      this.fieldType = item.fieldType;
      this.fieldOptions = item.fieldOptions;
      this.showPicker = true;
    },
    // 选择选项
    checkValueField(visitorFieldList, val) {
      visitorFieldList.forEach((element) => {
        if (element.fieldType == this.fieldType) {
          element.fieldValue = val;
        }
      });
      this.showPicker = false;
    },
    openDate(item) {
      this.fieldType = item.fieldType;
      this.catchItem = item;
      this.showDate = true;
    },
    dateValueChange(item, val) {
      item.fieldValue = moment(val).format("YYYY-MM-DD");
      this.showDate = false;
    },
    // 人脸录入
    faceChange(item, res, obj) {
      if (obj) obj.personFaceUrl = res;
      else item.personFaceUrl = res;
    },
    // 身份证-获取焦点后监听身份证
    getIdCard(temp) {
      console.log(temp);
      window.readIdCard = this.scanIdCard;
    },
    // 身份证回调
    scanIdCard(res) {
      let obj = JSON.parse(res);
      if (obj) {
        this.item.visitorFieldList.forEach((ite) => {
          // 身份证
          if (ite.fieldType == 4) {
            ite.fieldValue = obj.name;
          } else if (ite.fieldType === 6) {
            ite.fieldValue = obj.id;
          } else if (ite.fieldType === 8) {
            ite.fieldValue = obj.sex;
          }
        });
      }
      window.readIdCard = () => {};
    },
    checkIdCard(val) {
      return isIdcard(val) === "";
    },
    checkPhone(val) {
      return phoneReg.test(val);
    },
    // 校验字符串
    checkText(val) {
      return /^(?!\s)[a-zA-Z0-9\u4e00-\u9fa5`~!@%^*()_\\/\-+=<>?:"{}|,.;'[\]·！￥¥（）—《》？：“”【】、；‘，。\n\s]+$/.test(
        val
      );
    },
    // 失去焦点校验数据
    blurChange(temp) {
      if (!this.chaeckTraveller(temp)) {
        return;
      }
      evidenceOneMemberVisitor({
        merchantId: localStorage.merchantId,
        evidence: temp.fieldValue,
      })
        .then((res) => {
          if (res && res.visitorFieldList !== null) {
            this.formData.visitorFieldList.forEach((item) => {
              res.visitorFieldList.forEach((temp) => {
                if (item.fieldType === temp.fieldType) {
                  item.fieldValue = temp.fieldValue;
                  item.disabled = true;
                }
              });
            });
            if (this.formData.requiredPersonId && res.personId) {
              this.$set(this.formData, "personId", res.personId);
              this.$set(this.formData, "personFaceUrl", res.facePersonUrl);
            }
          }
        })
        .catch(() => {});
    },
    //出游人校验
    chaeckTraveller(temp) {
      let ok = true;
      let checks = { 5: phoneReg, 10: nameReg, 12: textReg };
      if (temp.required && !temp.fieldValue) {
        ok = false;
        this.newErr(`${temp.fieldName}必填`);
      }
      if (
        temp.fieldValue &&
        checks[temp.fieldType] &&
        !checks[temp.fieldType].test(temp.fieldValue)
      ) {
        ok = false;
        this.newErr(`"${temp.fieldName}"校验不通过`);
      }
      //身份证
      if (temp.fieldType == 6) {
        // 如果身份证验证不通过
        const msg = isIdcard(temp.fieldValue);
        if (temp.fieldValue && msg) {
          ok = false;
          this.newErr(msg);
        }
      }
      return ok;
    },
    // 抛出错误
    newErr(msg) {
      this.$toast(msg);
    },
    // 确认
    confirmActivate() {
      for (
        let index = 0;
        index < this.formData.visitorFieldList.length;
        index++
      ) {
        const temp = this.formData.visitorFieldList[index];
        this.chaeckTraveller(temp);
      }
      this.loading = true;
      let form = this.formData;
      let obj = {
        ticketNumber: this.auxiliaryId, // 票号
        userFieldInfoDTOS: [], // 游客信息集合
      };
      // 卡腕带开启
      if (form.requiredVoucherNo) {
        obj.voucherNo = form.voucherNo; // 卡腕带
      }
      // 人脸开启
      if (form.requiredPersonId) {
        obj.personId = form.personId; // 人脸id
        obj.personFaceUrl = form.personFaceUrl;
      }
      form.visitorFieldList.forEach((item) => {
        // 游客信息字段列表
        if (item.fieldValue) {
          // 排除null为空的值不提交
          obj.userFieldInfoDTOS.push({
            fieldName: item.fieldName,
            fieldType: item.fieldType,
            fieldValue: item.fieldValue,
          });
        }
      });
      // 如果有归类后的选项，过滤push到userFieldInfoDTOS数组
      form.IdTypeList.forEach((item) => {
        if (form.IdType === item.fieldType && item.fieldValue) {
          obj.userFieldInfoDTOS.push({
            fieldName: item.fieldName,
            fieldType: item.fieldType,
            fieldValue: item.fieldValue,
          });
        }
      });
      ticketActivite(obj)
        .then(() => {
          this.close();
          this.$toast("激活成功");
          this.$emit("success");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 关闭弹窗
    close() {
      this.$emit("close", false);
      // this.formData = {
      //   visitorFieldList: [], // 游客信息字段
      //   IdTypeList: [], // 出游人证件类型归类
      //   voucherList: [], // 凭证选项
      //   IdType: "", // 证件类型
      //   personId: "", // 人脸id
      //   personFaceUrl: "", // 人脸url
      // };
    },
  },
};
</script>

<style lang="less" scoped>
@import "../detail";
.pop-centent-center {
  min-height: 50vh !important;
  max-height: 70vh;
}
.person-face-url {
  width: 100%;
  padding: 0.2rem 0.38rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
